// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *


//
// MIXINS/GENERAL
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/mixins/general";

// --------------------------------------------------

.mod_article, .ce_text  {
   outline: 0;

}
