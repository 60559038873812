// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *

//
// COMPONENTS/TESTIMONIAL
// --------------------------------------------------

// VARIABLES
$testimonial__quote-font-weight:    700;

// USED FROM _variables.scss
// $base-spacing-unit, --sm, --lg

// --------------------------------------------------

.testimonial {
  .image_container {
    img {
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: $base-spacing-unit--sm;
    }
    
    .caption {
      text-align: center;
      font-style: italic;
    }
    
    &.float_left {
      margin-right: $base-spacing-unit--lg;
      
      @include media-query(screen-sm-max) {
        float: none;
        margin-right: 0;

      }
    }
    
    &.float_right {
      margin-left: $base-spacing-unit--lg;
        
      @include media-query(screen-sm-max) {
        float: none;
        margin-left: 0;
      }
    }
    
    &.float_below {
    
    }
  }
  
  blockquote {
    overflow: hidden;
    position: relative;
    display: block;
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    
    p:before,
    p:after {
      font-weight: 700;
    }
    
    p:before {
      content: open-quote;
      z-index: 0;
      position: absolute;
      top: 0;
      transform: translateX(-100%);
    }
    
    p:after {
      content: close-quote;
    }
    
    p {
    }
    
    footer {
      margin-top: $base-spacing-unit;
      font-size: 1rem;
    }
  }
  
  .float_above ~ blockquote,
  blockquote:first-child {
    @include make-width(8);
  }
}
