// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *


// FONTS
/* overpass-200 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/overpass-v2-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Overpass ExtraLight'), local('Overpass-ExtraLight'),
       url('../fonts/overpass-v2-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/overpass-v2-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/overpass-v2-latin-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/overpass-v2-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/overpass-v2-latin-200.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-200italic - latin */
@font-face {
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/overpass-v2-latin-200italic.eot'); /* IE9 Compat Modes */
  src: local('Overpass ExtraLight Italic'), local('Overpass-ExtraLightItalic'),
       url('../fonts/overpass-v2-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/overpass-v2-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/overpass-v2-latin-200italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/overpass-v2-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/overpass-v2-latin-200italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-700 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/overpass-v2-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Overpass Bold'), local('Overpass-Bold'),
       url('../fonts/overpass-v2-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/overpass-v2-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/overpass-v2-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/overpass-v2-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/overpass-v2-latin-700.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-700italic - latin */
@font-face {
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/overpass-v2-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Overpass Bold Italic'), local('Overpass-BoldItalic'),
       url('../fonts/overpass-v2-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/overpass-v2-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/overpass-v2-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/overpass-v2-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/overpass-v2-latin-700italic.svg#Overpass') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-500 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/ibm-plex-sans-v2-latin-500.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
       url('../fonts/ibm-plex-sans-v2-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ibm-plex-sans-v2-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/ibm-plex-sans-v2-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/ibm-plex-sans-v2-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/ibm-plex-sans-v2-latin-500.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

// VARIABLES
// General Colors
$color-gray-dark:   			#707070;
$color-gray-light:  			#f8f8f4;
$color-violett:  				#952b6a;
$color-green:  					#A2C617;

$color-highlight:  				#eaff96;
$color-brand:       			$color-violett;

$color-teaser-green1:  			#f8fae8;
$color-teaser-green2:  			#f4f7e1;
$color-teaser-green3:  			#edf0df;
$color-teaser-violett1:  		#F2E9EF;
$color-teaser-violett2:  		#EADFE6;

// Links
$color-links:     				$color-highlight;

// Button Colors
$color-btn:   					$color-links;
$color-btn-text:  				$color-gray-dark;


// Page
$color-page-background:   			#fff;
$color-page-background-light:   	$color-gray-light;
$color-page-background-green:		$color-teaser-green1;
$color-page-background-violett:		$color-teaser-violett1;
$color-page-background-highlight:	$color-highlight;
$color-text:              			$color-gray-dark;


// Base Font Settings
$base-font-size:          		1rem;
$base-line-height:        		1.625;

$base-font-size--xs:      		1rem;

$base-font-size--lg:      		1.125rem;
$base-font-size--xl:      		1.25rem;

$base-font-family-serif:        "IBM Plex Sans", Georgia, "Times New Roman", serif;
$base-font-family-sans-serif:   "Overpass", "Helvetica Neue", Helvetica, sans-serif;

$base-font-family:               $base-font-family-sans-serif;

// Base Spacing Units
$base-spacing-unit:				1.625rem;


// Base Border Settings
$base-border-radius:			4px;
$base-border-width:   			1px;
$base-border-color:   			currentColor;

// IMG
$base-img-shadow: 				0 0 5px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$base-overlay-background: 		rgba(0,0,0,0.8);

// IE Support
$ie9-support: true;


// --------------------------------------------------

@import "../../../nutshell/scss/variables";

// --------------------------------------------------
