// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *


//
// COMPONENTS/HERO
// --------------------------------------------------

// VARIABLES
$hero__color-background: $color-gray-dark;
$hero__color-text:       $color-text--inverted;
$hero__font-family:      $base-font-family-sans-serif;
$hero__font-weight:      700;

// USED FROM _variables.scss
// $base-spacing-unit, --lg

// USED FROM _layout.scss
/// $header-height

// --------------------------------------------------

// hero
// [1] every .inside container has position relative by contao default
// [2] calculate height based on padding for mod_article, including fallback
// [3] flexbox fix for IE10 & IE11, see http://caniuse.com/#feat=flexbox

.mod_article.hero {
  @include wrapper-max(none);
  
  background: $hero__color-background;
  color: $hero__color-text;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  z-index: 0;
  
    
  > .inside {
    @include padding-default;
    @include wrapper-max;

    position: static; // [1]
    min-height: inherit;
    overflow: hidden;
    display: flex;
    flex: 0 0 auto;
  }
  
  &--full {
    min-height: 100vh; // [2]
    min-height: calc(100vh - #{2 * $base-spacing-unit--lg}); // [2]
    height: inherit;
    
    > .inside {
      .ie10 &,
      .ie11 & {
      	height: calc(100vh - #{2 * $base-spacing-unit--lg}); // [2][3]
      }
      
      @if($ie9-support == true) {
        .ie9 & {
    	    height: calc(100vh - #{2 * $base-spacing-unit--lg}); // [2][3]
    	  }
      }
    }

    
    @media screen and (min-height: 800px) {
    	min-height: 90vh; // [2]
    	min-height: calc(90vh - #{2 * $base-spacing-unit--lg}); // [2]

    	
    	> .inside {
      	.ie10 &,
      	.ie11 & {
      	  height: calc(90vh - #{2 * $base-spacing-unit--lg}); // [2][3]
        }
        
        @if($ie9-support == true) {
          .ie9 & {
      	    height: calc(90vh - #{2 * $base-spacing-unit--lg}); // [2][3]
      	  }
        }
    	}
    }
  }
  
  &--small {
    @include media-query(screen-xs) {
		min-height: 500px;
		margin-top: 0;
		
	  }
     
    @include media-query(screen-sm) {
		min-height: 48vh;
		margin-top: 0;
	  }
	  
	  @include media-query(screen-md) {
		min-height: 52vh;
		margin-top: 3.3rem;
		margin-bottom: 0;
	  }
    
    @include media-query(screen-xl) {
	    min-height: 52vh;
		margin-top: 4rem;
		margin-bottom: 4rem;
	  } 
	  
   }
}

// [1] IE10 calculating wrong widths, when max-width isn't set
.hero__text {
  margin-top: $base-spacing-unit;    
  position: relative;
  z-index: 1000;
  text-align: center;
  align-self: flex-end;
  
  @include media-query(screen-md) {
    align-self: center;
  }
  
  h1 {
	  @include media-query(screen-xs-max) {
		  font-size: 1.5rem;
	  }
  }
  
  &--left {
    text-align: left;
  }
  
  &--right {
    text-align: right;
  }
  
  &--top {
    top: 0;
    align-self: flex-start;
  }
  
  &--bottom {
    bottom: 0;
    align-self: flex-end;
    margin-bottom: 0;
  }
  
  @if($ie9-support == true) {
    .ie9 .hero--full & {
      position: absolute;
      bottom: $base-spacing-unit;
    }
    
    @include media-query(screen-md) {
      .ie9 .hero--full & {
        position: relative;
        @include vertical-align;
      }
    }
  }
  
  .ie10 & {
	  max-width: 100%; // [1]
  }
}

.hero__image .image_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

/*.hero__image--half .image_container {
	position: relative;
}*/



.mod_article.hero__half{
	min-height: 500px;
	
	@include media-query(screen-offcanvas) {
			padding-left: 0rem;		    	
	    }

		
		
	.hero__text--half {
	  align-self: flex-end;
	  margin-bottom: 0;
	  background: rgba(255, 255, 255, 0.8);
	  position: absolute;
	  padding: 1.6rem 1.6rem 0 1.6rem;
	  
	  @media screen and (min-width: 0) {
		   width: 86.5%;
		   bottom: 1rem;
	  }
	  
	  @media screen and (min-width: 35em) {
		   width: 88%;
	  }
	  
	  @media screen and (min-width: 38em) {
		   width: 90%;
	  }
	  
	  @media screen and (min-width: 48em) {
		   width:50%;
		}	
		
  		@media screen and (min-width: 53em) {
		   margin-top: 10%;
		   background: rgba(255, 255, 255, 0.8);
		   padding: $base-spacing-unit;
		   margin-left: -4.8rem;
		   position: relative;
		   top: 0;
		   bottom: 0;
	    } 
	}
	
	.m-l-2{
		margin-left: 0;
		
		@include media-query(screen-offcanvas) {
			margin-left: 3rem;	
			    	
	    }
	}
	
	.hero__image {
		margin-bottom: 0;
	
	 .image_container {
	   position: absolute;
	   top: 0;
	   right: 0;
	   bottom: 0;
	   left: 0;
	   z-index: -1;
	   		
	   		@include media-query(screen-offcanvas) {
		    	position: relative;
	    	}
  
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			
			@include media-query(screen-offcanvas) {
		    	max-width: 100%;
			    height: auto;
	    	}
		}
		}
		}
} 

.hero__text--left--green {
	  margin-left: 0;
	  bottom: 0;
	  align-self: flex-end;
	  margin-bottom: 0;
	  padding: 1.6rem 1.6rem 0 1.6rem;
	  background: $color-teaser-green1;
	        	
      	@media screen and (min-width: 0) {
		   width: 86.5%;
		   bottom: 1rem;
		   position: absolute;
	  	}
	  
	  	@media screen and (min-width: 35em) {
		   width: 88%;
	  	}
	  
	  	@media screen and (min-width: 38em) {
		   width: 90%;
	  	}
       	
       	@include media-query(screen-md) {
	   		position: relative; 
	   		margin-left: 3rem;
	   		margin-top: 5rem; 
	   		bottom: 0;
	   		width: 50%;
	   	}
	   	
	   	@include media-query(screen-lg) {
		   	 bottom: 5rem;
		   	 margin-left: 3rem;
		   	 width: 33.333%
	    }
	  
	    @include media-query(screen-offcanvas) {
			padding: $base-spacing-unit;
	    }    
}

.hero__text--left--violett {
	  margin-left: 0;
	  bottom: 0;
	  align-self: flex-end;
	  margin-bottom: 0;
	  padding: $base-spacing-unit $base-spacing-unit 0 $base-spacing-unit;
	  background: $color-teaser-violett1;
	  
	    @media screen and (min-width: 0) {
		   width: 86.5%;
		   bottom: 1rem;
		   position: absolute;
	  	}
	  
	  	@media screen and (min-width: 35em) {
		   width: 88%;
	  	}
	  
	  	@media screen and (min-width: 38em) {
		   width: 90%;
	  	}
       	
       	@include media-query(screen-md) {
	   		position: relative; 
	   		margin-left: 3rem;
	   		margin-top: 5rem; 
	   		bottom: 0;
	   		width: 50%;
	   	}
	   	
	   	@include media-query(screen-lg) {
		   	 bottom: 5rem;
		   	 margin-left: 3rem;
		   	 width: 33.333%
	    }
	  
	    @include media-query(screen-offcanvas) {
			padding: $base-spacing-unit;
	    }    

}

.hero__text--left--white {
	  margin-left: 0;
	  bottom: 0;
	  align-self: flex-end;
	  margin-bottom: 0;
	  padding: $base-spacing-unit $base-spacing-unit 0 $base-spacing-unit;
	  background: rgba(255, 255, 255, 0.8);
	  	  
	    @media screen and (min-width: 0) {
		   width: 86.5%;
		   bottom: 1rem;
		   position: absolute;
	  	}
	  
	  	@media screen and (min-width: 35em) {
		   width: 88%;
	  	}
	  
	  	@media screen and (min-width: 38em) {
		   width: 90%;
	  	}
       	
       	@include media-query(screen-md) {
	   		position: relative; 
	   		margin-left: 3rem;
	   		margin-top: 5rem; 
	   		bottom: 0;
	   		width: 50%;
	   	}
	   	
	   	@include media-query(screen-lg) {
		   	 bottom: 5rem;
		   	 margin-left: 3rem;
		   	 width: 33.333%
	    }
	  
	    @include media-query(screen-offcanvas) {
			padding: $base-spacing-unit;
	    }    
}

