// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *


//
// COMPONENTS/FORMS
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/components/forms";

// --------------------------------------------------

input.text, input.captcha, textarea {
	border: none;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
	  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
	   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
	   transition: all 0.3s ease 0s;
	
}

input.radio,
input.checkbox {
	vertical-align: top;
}

.widget-radio--list {
	.radio_container {
		> span {
			display: block;
		}
	}
}

.widget-checkbox--list {
	.checkbox_container {
		> span {
			display: block;
		}
	}
}

.form__seo-check {
	@include media-query(screen-md) {
    	width: 100%;
  	}
  
  	@include media-query(screen-lg) {
		width: 120%;
     }
}

.form__seo-analyse,
.form__webhosting,
.form__seo-workshop {
	display :none;
}

