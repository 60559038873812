// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *

//
// COMPONENTS/PORTFOLIO
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss
// $base-img-shadow
// $base-overlay-background


// USED FROM _grid.scss
// $grid__gutter - use the space horizontal and vertical


// --------------------------------------------------

// [1] prevent image shadow being cutted
// [2] make caption also clickable if there is a link around image (not working in <=IE10)
.portfolio {
  margin-bottom: $grid__gutter;
  font-weight: 700;
  font-family: $base-font-family-sans-serif;
  overflow: visible; // [1]
  
  .image_container {
    position: relative;
    
    a::before {
      @include vertical-align();
      
      content: attr(title);
      position: absolute;
      z-index: 1;
      color: #fff;
      text-align: center;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s 0.1s;
      padding: $base-spacing-unit;
      pointer-events: none; // [2]
    }
    
    a:after {
      content: "";
      background: $base-overlay-background;
      display: block;
      vertical-align: middle;
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
    }
    
    a {
      position: relative;
      z-index: 1;
      display: block;
    }
    
    /*img {
      box-shadow: $base-img-shadow;
    }*/
  }
  
  .caption { 
    text-align: center;
    width: 100%;
    padding: $base-spacing-unit--xs;
  }
  
  &:hover .image_container a:before,
  &:hover .image_container a:after {
    visibility: visible;
    opacity: 1;
  }
  
  &:hover .image_container a:before {
    transform: translateY(-40%);
  }
}

// [1] make caption also clickable if there is a link around image (not working in <=IE10)
.portfolio--hover {
  
  .image_container {
    position: relative;
    
    a:before {
      display: none;
    }
    
    &:after {
      content: "";
      background: $base-overlay-background;
      display: block;
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
    }
  }
  
  .caption { 
    @include vertical-align();
    
    position: absolute;
    z-index: 1;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s 0.1s;
    padding: 0 $base-spacing-unit;
    pointer-events: none; // [1]
  }
  
  &:hover .image_container:after,
  &:hover .image_container a:after {
    visibility: visible;
    opacity: 1;
  }
  
  &:hover .caption {
    opacity: 1;
    visibility: visible;
    transform: translateY(-40%);
  }
}


// portfolio white Background with shadow
.portfolio--background,
.img--background figure  {
	background-color: #fff;
	padding: $base-spacing-unit--xs;
	-webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	   transition: all 0.3s ease 0s;
	   margin-bottom: 1rem;
	 }

// projekte cols
/*.projekte {
	.col-md-4 {
			width: 60%;
		}
	
		.col-md-6{
			width: 100%;
		}
		
		.col-md-2{
			width: 40%;
		}	
		
	@include media-query(screen-offcanvas) {
		.col-md-4 {
			width: 28%;
			position: absolute;
			margin-left: 55%;
			bottom: 1.7rem;
			}
	
		.col-md-6{
			width: 55%;;
		}
		
		.col-md-2{
			width: 16.66667%;
			position: absolute;
			margin-left: 83%;
			bottom: 1.7rem;			
		}	
	}
}

.web {
	
	.col-md-1{
			width: 60%;
				
		}
		
		.col-md-2 {
			width: 40%;
		}
		
			
		.col-md-5{
			width: 100%;
		}
		
		.col-md-3{
			display: none;			
		}	
		
		.col-md-4 {
			display: none;
		}

	
		
	@include media-query(screen-offcanvas) {
		.col-md-1{
			width: 19.583325%;
			position: absolute;
			margin-left: 0;			
			bottom: 5rem;	
				
		}
		
		.col-md-2 {
			width: 9.583325%;
			position: absolute;
			margin-left: 19.583325%;
			bottom: 5rem;
		}
		
			
		.col-md-5{
			margin-left: 29.16665%;
			width: 41.66667%;
		}
		
		.col-md-3{
			width: 19.583325%;
			position: absolute;
			margin-left: 70.83335%;			
			bottom: 5rem;
			display: block;			
		}	
		
		.col-md-4 {
			width: 9.583325%;
			position: absolute;
			margin-left: 90.416675%;
			bottom: 5rem;
			display: block;
		}

		
			
	}
}*/
.flex .col-xs-6 {
@include media-query(screen-xs)  {
		flex: 0 0 auto;
		-webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
    	/*-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;*/
}

@include media-query(screen-md) {
	flex: none;

}
}

.top-xs {
	@include media-query(screen-xs)  {
    	-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		display:flex;
		flex-wrap: wrap;
    }
     
    @include media-query(screen-lg) {
	 	flex-wrap: nowrap;
	}
}

.middle-xs {
	@include media-query(screen-xs)  {
    	-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		display:flex;
		flex-wrap: wrap;
    }
     
    @include media-query(screen-lg) {
	 	flex-wrap: nowrap;
	}
}

.bottom-xs{ 
	@include media-query(screen-xs)  {
    	-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		display:flex;
		flex-wrap: wrap;
     }
     
    @include media-query(screen-lg) {
	 	flex-wrap: nowrap;
	}
}

/*.col-xs-1 {
    -webkit-flex-basis: 8.333%;
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%
}

.col-xs-2 {
    -webkit-flex-basis: 16.666%;
    -ms-flex-preferred-size: 16.666%;
    flex-basis: 16.666%;
    max-width: 16.666%
}

.col-xs-3 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%
}

.col-xs-4 {
    -webkit-flex-basis: 33.333%;
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    ;
    max-width: 33.333%
}

.col-xs-5 {
    -webkit-flex-basis: 41.667%;
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%
}

.col-xs-6 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%
}

.col-xs-7 {
    -webkit-flex-basis: 58.333%;
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%
}

.col-xs-8 {
    -webkit-flex-basis: 66.667%;
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%
}

.col-xs-9 {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%
}

.col-xs-10 {
    -webkit-flex-basis: 83.333%;
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%
}

.col-xs-11 {
    -webkit-flex-basis: 91.667%;
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%
}

.col-xs-12 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%
}*/
