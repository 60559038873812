// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *

//
// COMPONENTS/MEDIA
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss
// $base-spacing-unit
// $base-img-shadow

// --------------------------------------------------

@import "../../../nutshell/scss/components/media";

// --------------------------------------------------

.ce_image {
  margin-bottom: $base-spacing-unit;
}

.ce_gallery {
  overflow: visible;
  img {
    box-shadow: $base-img-shadow;
  }
}

.img_rounded {
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}

.ce_text img {
	border: 1px solid #ccc;
}

// [1] default class to create object-fit images
// [2] add by js for browsers that don't support the object-fit attribute
.image--object-fit .image_container { // [1]
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.compat-object-fit { // [2]
  background-size: cover;
  background-position: center center;
  
  img {
    opacity: 0;
  }
}

// fix for IE9: picture-element +  mediaelement.js + object-fit
@if($ie9-support == true) {
  .image_container .mejs-container {
    display: none;
  }
}

// ce_youtube
// contao 4.4, does no longer load youtube videos via mediaelement.js
// instead it uses an iframe. Nutshell Framework uses the .ce_youtube container
// to calculate the video ratio for responsiveness. Adding a Headline inside 
// .ce_youtube will cause wrong video dimensions
// [1] Warning not to use a Headline in Youtube
.ce_youtube {
  #{headings()} {
    position: absolute;
    z-index: 1001;
    background: $color-alert-bg;
    
    &:after {
	    content: " +++ Warning: do not use headlines inside ce_youtube, see _media.scss +++"; // [1]
    }
  }
}
