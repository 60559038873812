// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *


//
// BASE/TYPE
// --------------------------------------------------

// VARIABLES
$color-alert-bg:					lighten($color-brand, 35%);
$color-alert-border:			darken($color-alert-bg, 10%);

$color-code-bg:						$color-gray-light;

// heading
$headings__font-family:		$base-font-family-sans-serif;
$headings__font-weight:		700;

$headlines: (
  h1: (
    sizes: (
      xs: 1.85rem,
      sm: 1.85rem,
      md: 1.85rem,
      lg: 1.85rem,
      xl: 1.85rem
    ),
    line-height: 1.2,
    margin-top: 2rem,
    margin-bottom: 2rem
  ),
  h2: (
    sizes: (
      xs: 1.55rem,
      sm: 1.55rem,
      md: 1.55rem,
      lg: 1.55rem,
      xl: 1.55rem
    ),
    line-height: 1.25,
    margin-top: 2rem,
    margin-bottom: 1.5rem
  ),
  h3: (
     sizes: (
      xs: 1.4rem,
      sm: 1.4rem,
      md: 1.4rem,
      lg: 1.4rem,
      xl: 1.4rem
    ),
    line-height: 1.25,
    margin-top: 2rem,
    margin-bottom: 1.5rem
  ),
  h4: (
    sizes: (
      xs: 1.3rem,
      sm: 1.3rem,
      md: 1.3rem,
      lg: 1.3rem,
      xl: 1.3rem
    ),
    line-height: 1.25,
    margin-top: 1.5rem,
    margin-bottom: 0.5rem
  ),
  h5: (
    sizes: (
      xs: 1.2rem,
      sm: 1.2rem,
      md: 1.2rem,
      lg: 1.2rem,
      xl: 1.2rem
    ),
    line-height: 1.25,
    margin-top: 1.5rem,
    margin-bottom: 1.5rem
  ),
  h6: (
    sizes: (
      xs: 1rem,
      sm: 1rem,
      md: 1rem,
      lg: 1rem,
      xl: 1rem
    ),
    line-height: 1.25,
    margin-top: 1.5rem,
    margin-bottom: 0.5rem
  ),
) !default;

// paragraph
$paragraph__link-color:             $color-gray-dark !default;
$paragraph__link-decoration:        underline;
$paragraph__link-decoration--hover: none;

// code
$code__color:									$color-highlight;
$pre__color-background:							$color-gray;
$pre__color:									$color-text--inverted;

// USED FROM _variables.scss
// $base-spacing-unit
// $base-border-radius

// --------------------------------------------------

@import "../../../nutshell/scss/base/type";

// --------------------------------------------------


// heading sizes
// [1] make sure, that only the first headline inside an element or module 
// is styled this way 
.heading--1 {
	&.ce_headline {
  	@extend %h1;
  }
  
  > #{headings()} { // [1]
		&:first-child {
			@extend %h1; 
		}
	}
}

.heading--2 {
	&.ce_headline {
		@extend %_h2; 
	}
	
	> #{headings()} { // [1]
		&:first-child {
			@extend %_h2; 
		}
	}
}

.heading--3 {
	&.ce_headline {
		@extend %_h3; 
	}
	
	> #{headings()} { // [1]
		&:first-child {
			@extend %_h3; 
		}
	}
}

.heading--4 {
	&.ce_headline {
		@extend %_h4; 
	}
	
	> #{headings()} { // [1]
		&:first-child {
			@extend %_h4; 
		}
	}
}

.heading--5 {
	&.ce_headline {
		@extend %_h5; 
	}
	
	> #{headings()} { // [1]
		&:first-child {
			@extend %_h5; 
		}
	}
}

.heading--6 {
	&.ce_headline {
		@extend %_h6; 
	}
	
	> #{headings()} { // [1]
		&:first-child {
			@extend %_h6; 
		}
	}
}

// heading alignment
.heading--left {
  text-align: left;
}

.heading--center {
  text-align: center;
}

.heading--right {
  text-align: right;
}

h1 {
	hyphens: auto;
	-webkit-hyphens: auto;
	-webkit-hyphenate-limit-chars: auto 3;
	-webkit-hyphenate-limit-lines: 4;
	-ms-hyphens: auto;
	-ms-hyphenate-limit-chars: auto 3;
	-ms-hyphenate-limit-lines: 4;
	
	@include media-query(screen-offcanvas) {
		hyphens: none;
		-webkit-hyphens: none;
		-ms-hyphens: none;
	}
}

h3.heading--green {
  color: #BFDE40;
}

// Paragraph
p {
  a {
    color: $paragraph__link-color;
    text-decoration: $paragraph__link-decoration;
    
    &:hover {
      text-decoration: $paragraph__link-decoration--hover;
    }
  }
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--attention {
  font-size: 1.25em;
  line-height: 1.6;
}

.text--alert {
	background: $color-alert-bg;
	padding: $base-spacing-unit;
	border-radius: $base-border-radius;
	border: $base-border-width solid $color-alert-border;
	
	#{headings()} {
		@extend %h4;
			
		margin-top: 0 !important;
	}
}

// Lists
ul,
ol {
  padding: 0;
  margin-top: 0;
}

.mod_article ul li, 
.mod_article ol li {
  font-family: $base-font-family-sans-serif;
  line-height: 1.425;
  margin-bottom: 0.8rem;
}

// Blockquotes
blockquote {
  margin: $base-spacing-unit 0;
  font-size: 1.4rem;
  font-weight: bold;
  overflow: hidden;
  position: relative;
  display: block;
   padding-left: 1rem;
    padding-right: 1rem;
 
  		p:before {
  			content: open-quote;
  			position: absolute;
  			z-index: 0;
  			top: 0;
  			-webkit-transform: translateX(-100%);
  			-ms-transform: translateX(-100%);
  			transform: translateX(-100%);
		}

		p:after {
			content: close-quote;
		}
}

// code
// [1] contao 4.4: used inside ce_code element
code {
	background: $color-code-bg;
	display: inline-block;
	padding-left: 4px;
	padding-right: 4px;
	border-radius: $base-border-radius;
	
	// [1]
	pre & {
		background: none;
		color: inherit;
	}
}

.typewriter p{
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid $color-brand; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: $color-brand; }
}
