// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *


//
// COMPONENTS/LINKS
// --------------------------------------------------

// VARIABLES
$btn-font-family: 					$base-font-family-sans-serif;
$btn-font-weight: 					700;

$btn--secondary-color:				$color-text;
$btn--secondary-color--dark:		darken($color-btn,35%);

$btn--primary-highlight-bg:			$color-text;
$btn--primary-highlight-text: 		$color-text--inverted;

$btn--secondary-highlight-text:		$color-text--inverted;

// USED FROM _variables.scss
// $base-spacing-unit--xs, --sm, --xl
// $base-border-radius
// $base-border-width
// $base-border

// --------------------------------------------------

@import "../../../nutshell/scss/components/links";

// --------------------------------------------------

%button {
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  cursor: pointer;
}

// btn--primary
a.btn--primary,
span.btn--primary,
button.btn--primary,
.more a {
	
	  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	   transition: all 0.3s ease 0s;
	   

	p & {
		margin-bottom: 0.5rem;
		text-decoration: none;
	}
}


// [1] button used in a .band--highlight container
div.btn--primary {
	border-radius: $base-border-radius;
	display: inline-block;
	-webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	   transition: all 0.3s ease 0s;
	    cursor: pointer;
  
  a {
    .band--highlight & { // [1]
      background: $btn--primary-highlight-bg;
      color: $btn--primary-highlight-text;
    }
  }
}

.band--highlight .btn--primary {
	background: #FFF;
      color: $color-text;
}

a.btn--secondary,
span.btn--secondary,
button.btn--secondary {
  border-radius: $base-border-radius;
  color: $btn--secondary-color;
  border: $base-border-width solid currentColor;
  background-color: transparent;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	   transition: all 0.3s ease 0s;
	   text-decoration: none;
	   cursor: pointer;
  
  &:hover {
    color: $btn--secondary-color--dark;
    border-color: $btn--secondary-color--dark;
    background-color: transparent;
  }
  
  .band--highlight & {
    color: $btn--secondary-highlight-text;
    border-color: $btn--secondary-highlight-text;
    
    &:hover {
      opacity: 0.5; 
    }
  }
}

div.btn--secondary a {
  border-radius: $base-border-radius;
  color: $color-text;
  margin-bottom: 0.5rem;
  border: $base-border-width solid currentColor;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	   transition: all 0.3s ease 0s;
  
  &:hover {
    color: $btn--secondary-color--dark;
    border-color: $btn--secondary-color--dark;
  }
  
  .band--highlight & {
    color: $btn--secondary-highlight-text;
    border-color: $btn--secondary-highlight-text;
    
    &:hover {
      opacity: 0.5; 
    }
  }
}

div.btn--feedback {
	position: fixed;
	right: 0;
	top: $base-spacing-unit--xl;
	transform: rotate(-90deg) translateY($base-spacing-unit--xs);
	transform-origin: bottom right;
	transition: transform 0.1s;
	
	&:hover {
		transform: rotate(-90deg) translateY(0);
	}
	
	a {
		padding: $base-spacing-unit--xs $base-spacing-unit--sm;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border: $base-border;
		border-bottom: 0;
	}
}


.more a{
  background: $button-bg;
  color: $color-btn-text;
  margin-bottom: $base-spacing-unit;
  border-radius: $button-border-radius;
  padding: $button-spacing;
  display: inline-block;
  border: $button-border-width solid transparent;
  font-family: $button-font-family;
  font-weight: $button-font-weight;
  line-height: $button-line-height;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  font-weight: $btn-font-weight;
  cursor: pointer;
  
  &:hover {
    background: $button-bg-hover;
  }
}


.back {
    font-family: $base-font-family-sans-serif;
    margin-top: 0;
    border: none;
    
    a {
      display: block;  
      text-decoration: none;  
      color: $color-text;
		font-size: 0.8rem;
      
      
      &:before {
       font-family: 'FontAwesome';
		content: "\f137";
		font-size: 1.2rem;
		margin-left: 0.1rem;
		color: $color-brand;
        margin-right: $base-spacing-unit--sm / 2;
      }
      
      &:hover {
	      color: $color-brand;
      }
    }
}

// cookiebar
.cookiebar {
	background-color: $color-gray-dark; 
	font-size: 0.8rem; 

.cookiebar__button {
	background-color: $color-highlight;
	color: $color-gray-dark;  
	
	&:hover {
		background-color: #e0ff63;
	}
}
}