// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *

//
// MIXINS/RESPONSIVE
// --------------------------------------------------

// VARIABLES
$wrapper-max:   66.625em; // ~ lg: 960px, xl: 1200px

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/mixins/responsive";

// --------------------------------------------------

$additional_breakpoints: (
  offcanvas: (
    min:        53em, // 768px
    max:        (53em - 0.0625em), // 767px
  )
) ;

$all_breakpoints: map-merge($breakpoints, $additional_breakpoints);

/*#main .inside .mod_article{ 
 @include media-query(screen-xs) {
    	border: 1px solid #00f; //blau
  	}
  @include media-query(screen-sm) {
       border: 1px solid #fff; //weiss
  }
  @include media-query(screen-md) {
       border: 1px solid #ff0; //gelb
  }
  @include media-query(screen-lg) {
    border: 1px solid #ccc;  //grau 
     }
  @include media-query(screen-xl) {
    border: 1px solid #0ff; //türkis
  }
}*/

.mod_article.band.band--whitegray .inside  {
	@include media-query(screen-xs) {
    	padding-left:2.8rem;
    	padding-right: 2.8rem;
  	}
  	@include media-query(screen-md) {
    	padding-left: 0.9375rem;
		padding-right: 0.9375rem;
  	}
}

.mod_article.band .inside,
.mod_article .inside,
.projekte .mod_article .inside  {
	@include media-query(screen-xs) {
    	padding-left: 2rem;
    	padding-right: 2rem;
  	}
  	
  	@include media-query(screen-md) {
    	padding-left: 0.9375rem;
		padding-right: 0.9375rem;
  	}
}

#footer .inside {
	@include media-query(screen-xs) {
    	padding-left: 2rem;
    	padding-right: 2rem;
  	}
  	
  	@include media-query(screen-md) {
    	padding-left: 0.9375rem;
		padding-right: 0.9375rem;
   	}
}