// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *

//
// COMPONENTS/NOTES
// --------------------------------------------------

.footer__note {
  font-family: $base-font-family-sans-serif;
  font-weight: 200;
  margin-bottom: 1rem;

  a {
	  color: $color-gray-dark;
	  text-decoration: none;
	 
	 &:hover {
		 text-decoration: underline;
	 }
  }
  
  @include media-query(screen-offcanvas) {
	  float: left;
	  margin-bottom: 0;
	}
	
	.footer__image{
		width:1.4rem;
		display: inline-block;
		vertical-align: top;
		margin-right: 1.5rem;
	}
	
	.footer__content{
		display: inline-block;
	}
}


	 .social-media {
		 margin-left: 2.5rem;
	 
		 a {
		 	display: inline-block;
		 	float: left;
		 	margin-left: 0.4rem;
		 	margin-top: 1rem;
		 }
		 
		 img {
			 width: 24px;
			 height: auto;
		 }	
}
