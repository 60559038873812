// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *

//
// BASE/LAYOUT
// --------------------------------------------------

// VARIABLES
$header-color-background:                   	#fff;
$header-color-text--invert:                 	#fff;
$header-height:                     			4rem;

// --------------------------------------------------

@import "../../../nutshell/scss/base/layout";

// --------------------------------------------------

// nav--mobile--active
html.html--fixed {
  @include media-query(screen-offcanvas-max) {
    overflow-y: hidden;
    position: relative;
  }
}

// [1] sticky footer via flexbox, see https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/components/site.css
body {
	height: 100vh; // [1]
}

// #wrapper
// [1] sticky footer via flexbox 
[id="wrapper"] {
  display: flex; // [1]
  flex-direction: column; // [1]
  height: 100%; // [1]
}

// #header
[id="header"] {
  background: $header-color-background;
  position: fixed;
  z-index: 1001;
  left: 0;
  right: 0;
  height: $header-height;
  flex: none;
  color: $header-color-text;
  
  .inside {
    @include padding-default;
  }
  
  .nav-up {
  top: -$header-height;
}
  
}

// #container
// [1] sticky footer via flexbox 
// [2] IE10: sticky footer, fix gap between #container and #footer 
[id="container"] {
  flex: 1 0 auto; // [1]
  overflow: hidden; // [2]
  margin-top: 0;
}

.container-top [id="container"] {
  margin-top: 4rem;
  padding-top: 0;
}

.mod_article {
  padding-top:    $base-spacing-unit;
  padding-bottom: $base-spacing-unit;
    
  @include media-query(screen-lg) {
    padding-top:    $base-spacing-unit--lg;
    padding-bottom: $base-spacing-unit--lg;
  }
  
  @include media-query(screen-xl) {
    padding-top:    $base-spacing-unit--lg;
    padding-bottom: $base-spacing-unit--lg;
  }
  
  .inside {
	  > .last {
 	    margin-bottom: 0;
	  }
  }
}

.article--full {
  @include wrapper-max(none);
  
  padding: 0;
}

// #footer
[id="footer"] {	
	flex: none;
	border-top: 2px solid $color-gray-light;

  .inside {
    padding-top: $base-spacing-unit--sm;
    padding-bottom: $base-spacing-unit--sm;
  }
}

// band
.band {
  @include wrapper-max(none);

  padding-left: 0;
  padding-right: 0;
  position: relative;
  
  .inside {
    @include wrapper-max();
    @include padding-default();
  }
}

.band--highlight {
  background-color: $color-highlight;
  
  a{
	  color: $color-gray-dark;
	  text-decoration: underline;
  }
 }

.band--gray {
  background-color: $color-gray-light;
}

.band--whitegray {
  background: linear-gradient(180deg, #ffffff 50%, $color-gray-light 50%);
}

.band--green {
  background: $color-teaser-green1;
}

.band--green2 {
  background: $color-teaser-green2;
}

.band--violett {
  background: $color-teaser-violett1;
}

.band--violett2 {
  background: $color-teaser-violett2;
}


// teaser
.teaser--wrapper {
	padding-left: 0.2rem;
	padding-right: 0.2rem;
}

.teaser {
	padding: $base-spacing-unit;
}

.bg-teaser-green1{
	background-color: $color-teaser-green1;
	-webkit-flex:1;
	flex:1;
	
}

.bg-teaser-green2{
	background-color: $color-teaser-green2;
	-webkit-flex:1;
	flex:1;
}

.bg-teaser-green3{
	background-color: $color-teaser-green3;
	-webkit-flex:1;
	flex:1;

}

.bg-teaser-violett1{
	background-color: $color-teaser-violett1;
	-webkit-flex:1;
	flex:1;
}

.bg-teaser-violett2{
	background-color: $color-teaser-violett2;
	-webkit-flex:1;
	flex:1;
}