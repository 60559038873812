// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *


//
// TRUMPS/HELPER
// --------------------------------------------------

// VARIABLES


// USED FROM _variables.scss
// $color-text--inverted
// $base-border
// $base-spacing-unit

// USED FROM _grid.scss
// $grid__columns

// USED FROM _responsive.scss
// $breakpoints


// --------------------------------------------------

.text--inverted,
.text--inverted * {
  color: $color-text--inverted;
}

.text--color, .text--color a {
	color: $color-gray-dark;
}

.disabled, .disabled a,
figcaption{
	color: #a3a3a3;
	font-size: 0.8rem;
	padding-bottom: 1.6rem;
}

.mod_newslist figcaption {
	 	display: none;
 	}
 	
.mod_newsreader figcaption{
	padding-bottom: 0;
}

.border-top {
  border-top: $base-border;
}

.border-bottom {
  border-bottom: $base-border;
}

hr {
	padding-bottom: 0.6rem;
}

hr.border-half {
	border-top: $base-border;
	padding-top: 0.6rem;
	width: 50%;
}

.block--center {
  @include margin-auto;
}

.rotate--left {
	transform: perspective(800px) rotateY(20deg);
}

.rotate--right {
	transform: perspective(800px) rotateY(-20deg);
}

.box--shadow { 
	-webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	   transition: all 0.3s ease 0s;
}
   
// spacing classes for articles and elements beginning from .m-t-0 up to .m-t-5
@for $i from 0 through 5 {
  .m-t-#{$i},
  .mod_article.m-t-#{$i} {
    margin-top: $i * $base-spacing-unit;
  }
  
  .m-r-#{$i},
  .mod_article.m-r-#{$i} {
    margin-right: $i * $base-spacing-unit;
  }
  
  .m-b-#{$i},
  .mod_article.m-b-#{$i} {
    margin-bottom: $i * $base-spacing-unit;
  }
  
  .m-l-#{$i},
  .mod_article.m-l-#{$i} {
    margin-left: $i * $base-spacing-unit;
  }
  
  .p-t-#{$i},
  .mod_article.p-t-#{$i} {
    padding-top: $i * $base-spacing-unit;
  }
  
  .p-r-#{$i},
  .mod_article.p-r-#{$i} {
    padding-right: $i * $base-spacing-unit;
  }
  
  .p-b-#{$i},
  .mod_article.p-b-#{$i} {
    padding-bottom: $i * $base-spacing-unit;
  }
  
  .p-l-#{$i},
  .mod_article.p-l-#{$i} {
    padding-left: $i * $base-spacing-unit;
  }
}


.width70 .inside{
	max-width: 70em;
}

.article-lg-10 .inside {
	@include media-query(screen-xs) {
    	padding-left: 2rem;
    	padding-right: 2rem;
  	}
  	
  	@include media-query(screen-md) {
    	padding-left: 0.9375rem;
		padding-right: 0.9375rem;
  	}
}

// article widths container .article -[viewport]-[cols]
//
// [1] length($breakpoints) - go through length of available viewports (xs, sm, md, lg, xl == 5)
// [2] $current - actual viewport (e.g. "xs")
// [3] $i - counter, returns the actual grid-column
// [4] $grid__columns - go through available columns (e.g. 1 --> 12)
@for $size from 1 through length($breakpoints) { // [1]
  $x:   nth($breakpoints, $size); // [2]
  $current:   nth($x, 1); // [2]

  // padding creation
  @for $i from 1 through $grid__columns { // [3][4]
    .article-#{$current}-#{$i} {
      padding-left: 0;
      padding-right: 0;
      
      .inside { // [2][3]
        @include padding-default;
        @include margin-auto;
      }
    }
  }
  
  // width creation for every article- class
  @include media-query(screen-#{$current}) { // [2]

  	@for $i from 1 through $grid__columns { // [3][4]
  		.article-#{$current}-#{$i} .inside { // [2][3]
        width: percentage($i / $grid__columns);
        max-width: $wrapper-max * $i / $grid__columns;
  		}
  	}
  }
}

.right-6 {
	@include media-query(screen-lg){
	position: relative;
	right: 0;	
}
	
	@include media-query(screen-xl){
		right: -6em;
	}
}

.left-6 {
	@include media-query(screen-lg){
	position: relative;
	left: 0;	
}
	
	@include media-query(screen-xl){
		left: -6em;
	}
}

.top-2 {
	@include media-query(screen-sm){
	position: relative;
	top: 0;	
}
	
	@include media-query(screen-md){
		top: 2em;
	}
}

.top-4 {
	@include media-query(screen-sm){
	position: relative;
	top: 0;	
}
	
	@include media-query(screen-md){
		top: 4em;
	}
}

/*.anchor::before {
        display: block;
        content: "";
        height:7rem;
        margin: -7rem 0 0;
    }*/
.anchor {
	margin-top: -5rem;
	padding-top: 5rem;
}