// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *

//
// COMPONENTS/SLIDER
// --------------------------------------------------

// VARIABLES
$arrow-color:               inherit;
$arrow-size:                32px;
$menu-point-color:          inherit;
$menu-point-color--active:  $color-gray-dark;
$arrow-padding:				$base-spacing-unit--sm;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/components/slider";

// --------------------------------------------------

.slider {
	.slider-wrapper > * {
		margin-bottom: $base-spacing-unit--lg;
	}
	
	.slider-control a {
		width: calc(#{$arrow-size} + #{$arrow-padding} * 2);
		height: calc(#{$arrow-size} + #{$arrow-padding} * 2);
		overflow: hidden;
	}
}

.slider .slider-menu {
	@include media-query(screen-xs) {
	font-size: 2rem;
	bottom: 3rem;
	}
	
	@include media-query(screen-lg) {
	bottom: 4rem;
	}
	
	@include media-query(screen-xl) {
	bottom: 5rem;
	}
	
	b {
		color: #ccc;
	}
}

.slider .slider-control a:before{
	color: #ccc;
}

.slider-control .slider-menu {
	top: inherit;
	width: 50%;
	left: 50%;
	margin-left: -25%;
	font-size: 27px;
	text-align: center;
}

// Fullscreen Slider, used in combination with .mod_article.hero
.slider--hero {
  min-height: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  .content-slider,
  .slider-wrapper,
  .slider-wrapper > *,
  .image_container {
    min-height: inherit;
    
    .image_container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.content-slider {
	.slider--text_content{
		@include media-query(screen-xs) {
	  		position: relative;
	  		margin-left: 0.8rem;
	  		top: 2rem;
	  		/*float: left;
	  		width: 100%; */
	  		
	  		
	  		p:first-child {
		  		float: left;
		  		margin-right: 2rem;
	  		}
	  	}
		
		@include media-query(screen-lg) {
			position: absolute;
			right: 3rem;
			bottom: 0.5rem;
			top: initial;
			text-align: left;
			
			p:first-child {
		  		float: none;
		  		margin-right: 0;
	  		}
	    } 
	}
	
	figure {
		@include media-query(screen-xs) {
	  		padding-bottom: base-spacing-unit;
       	}
		
		@include media-query(screen-lg) {
			padding-bottom: 0;
	    } 		
	}
	
	.ce_text {
		@include media-query(screen-xs) {
	  		padding-bottom: 2rem;
       	}
		
		@include media-query(screen-lg) {
			padding-bottom: 0;
	    } 
		
	}
}

.slider h1 {
		@include media-query(screen-xs) {
			margin-top: $base-spacing-unit;
			margin-left: 1rem;
			margin-bottom: 0;
	  		text-align: left;
       	}
		
		@include media-query(screen-lg) {
			text-align: right;
			margin-top: 0;
	    } 
	}