// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// BASE/PAGE
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/base/page";

// --------------------------------------------------

// PUT YOUR OWN PAGE STYLES HERE

.euf_overlay {z-index: 9999;}

.euf_overlay__content {max-width: 600px;}