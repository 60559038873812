// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *

//
// COMPONENTS/ICONS
// --------------------------------------------------
.icon li{ 
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-family: $base-font-family-sans-serif;
  display: block;
}

.check__icon ul {
	margin-top: 0.6rem;

	li { 
		list-style-type: none;
		margin-left: 2rem;
		
		@include media-query(screen-offcanvas) {
		margin-left: 4rem;
		
		}
	
		&:before {
			font-family: 'FontAwesome';
			content: "\f058";
			color: $color-brand;
			margin-left: -0.9rem;
			position: relative;
			left: -0.7rem;
			}
		}
}