// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *


//
// COMPONENTS/LOGO
// --------------------------------------------------

.logo {
  float: left;
  	width: 10rem;
   margin-top: 1.1rem;
  
  a {
    color: inherit;
    text-decoration: none;
  }
}

.logo span {
    width: 0;
    height: 0;
    left: -1000px;
    top: -9000px;
    position: absolute;
    overflow: hidden;
    display: inline;
 }
 
a.svg {
 position: relative;
 display: inline-block;
 width: 100%;
 text-decoration: none;
}

a.svg:after {
  content: ""; 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left:0;
  text-decoration: none;
}

object {
  width: 100%;
}
