// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *

//
// COMPONENTS/TABLES
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/components/tables";

// --------------------------------------------------

// 1 Paket
.pricetable {
	padding: 2rem 1rem;
	
	@include media-query(screen-offcanvas) {
		padding: 2rem 6rem;
	}
	
	h1,h2,h3,h4,h5,h6{
		text-align: center;
		border-bottom: 1px solid $color-violett;
		padding-bottom: 1rem;
		color: $color-text;
		margin-bottom: 3rem;
	}
	
	ul{
		padding: 0 1.4rem 0.5rem 1.4rem;
	} 
	
	.pricedetails {
		border-top: 1px solid $color-violett;
		text-align: center;
		padding-top: 1rem;
		
		.price {
			font-size: 1.4rem;
			margin-bottom: 0;
		}
	}	
}
	
// 3 Pakete
.pricetable--3{
		padding: 2rem 1rem;
		
		h1,h2,h3,h4,h5,h6{
		color: $color-violett;
	}
	
	.pricedetails {
		.price {
			color: $color-violett;
		}
	}
}


.pricetable.check__icon ul li,
.pricetable--3.check__icon ul li {
	margin-left: 0;
}
