// *
// * Jumedia Webagentur GmbH
// * Jumedia Theme
// *


//
// COMPONENTS/NEWS
// --------------------------------------------------

// VARIABLES
$news-grid-columns--sm: 2;
$news-grid-columns--md: 2;
$news-grid-columns--lg: 2;
$news-hero-background-color: $color-teaser-green1;

// --------------------------------------------------

@import "../../../nutshell/scss/components/news";

// --------------------------------------------------

// [1] IE11: remove uncommon space between image and headline
.news-grid {
 	padding-top: 2rem;
  
  .layout_latest {
	margin-top: 0;
	margin-bottom: 0.4rem;
	background-color: #fff;
	
	@media screen and (min-width: 0){
		width: 100%;
		margin-left: 0.9375rem;
		margin-right: 0.9375rem;
	}	  

	@media screen and (min-width: 39.8em){
		width: 49%;
		margin-left: 0.2rem;
		margin-right: 0.2rem;
	}
	
	 .layout_latest__wrapper {
		 padding: $base-spacing-unit $base-spacing-unit--sm $base-spacing-unit--sm $base-spacing-unit--sm;
		 
	 }
    
    #{headings()} {
      @extend %h3;
    
      a {
        color: inherit;
      }
    }
    
    h2 {
	    margin-bottom: $base-spacing-unit;
	    margin-top: $base-spacing-unit--sm;
    }
  }
  

  .image_container {
    margin-top: $base-spacing-unit;
    margin-bottom: $base-spacing-unit;
    float: none;
    overflow: hidden; // [1]
  }
  
  .info {
    padding-top: $base-spacing-unit--sm;
    font-size: 0.85em;
    font-weight: 700;
    font-family: $base-font-family-sans-serif;
    color: $color-gray;
  }
  
  .pagination {
    @include padding-default;
  }
  
  display: flex;
  flex-wrap: wrap;
  
  
  .even {
	@media screen and (min-width: 0){
		bottom: 0;
	}	  

	@media screen and (min-width: 39.8em){
		position: relative; 
		bottom: 40px;
	}  
	}
  
  .even.first {	  
	  @media screen and (min-width: 0){
		margin-top: $base-spacing-unit; 
	}	  

	@media screen and (min-width: 39.8em){
		position: relative; 
		top: 0px; 
		margin-bottom: 48px;
		margin-top: 0;
	}
	  }

  .info {
    margin-top: auto;
  }
}

// [1] calculate height based on padding for mod_article, including fallback
// [2] override default margins from .news-list .image_container/.float_left/.float_right
.news-list--hero {
  margin-top: 0;
  margin-bottom: 0;
  min-height: 90vh; // [1]
  min-height: calc(90vh - #{2 * $base-spacing-unit--lg}); // [1]
  
  .layout_latest:after {
    display: none;
  }
  
  > * {
    height: 100%;
    min-height: inherit;
    display: flex;
  }
  
  h1 {
    line-height: 1.25;
  }
  
  .image_container,
  .float_left,
  .float_right,
  .float_above,
  .float_below {
    margin-top: 0; // [2]
    margin-bottom: 0; // [2]
    margin-left: 0; // [2]
    margin-right: 0; // [2]
  }
  
  .hero__image .image_container {
    background-color: $news-hero-background-color;
    max-width: none;
  }
  
  .hero__image .image_container:not(.compat-object-fit) img {
    opacity: 0.3;
  }
  
  .hero__image .image_container.compat-object-fit {
    opacity: 0.3;
  }
  
  .hero__text {
    margin-top: $header-height;
    margin-bottom: $header-height;
    position: relative;
    z-index: 1000;
    color: #fff;
    text-align: left;
    
    a {
      color: #fff;
    }
    
    &.float_above {
      @include make-width(8, left);
    }
  
    &.float_below {
      @include make-width(8);
      
      align-self: flex-end;
    }
    
    &.float_left {
      margin-left: percentage(4/12);
    }
    
    &.float_right {
      margin-right: percentage(4/12);
    }
  }
  
  .info {
    font-weight: 700;
    font-family: $base-font-family-sans-serif;
  }
  
  .teaser {
    font-size: 1.25rem;
    line-height: 1.6;
  }
  
  .more {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.news-full {
	margin-top: 3rem;
	@include media-query(screen-offcanvas) {
		margin-top: 0;
	}
  h2,h3,h4,h5,h6 {
	  margin-bottom: 0.8rem;
	  margin-top: 3rem;
	  line-height: 1.2;
	  color: $color-text;
  }	
  
  .mod_article .inside {
	  padding-left: 0;
	  padding-right: 0;
  }
  
  h2 + h3  {
	  margin-top: 1rem;
  }
  
  .info {
    font-weight: 300;
  }
  
  .back {
    font-family: $base-font-family-sans-serif;
    
    a {
      display: block;  
      text-decoration: none;  
      
      
      &:before {
       font-family: 'FontAwesome';
		content: "\f137";
		font-size: 1.2rem;
		margin-left: 0.1rem;
		color: $color-brand;
        margin-right: $base-spacing-unit--sm / 2;
      }
      
      &:hover {
	      color: $color-brand;
      }
    }
  } 
  
  ol, ul {
	  margin-left: 1.6rem;
  }
  
  .band {
	 padding: $base-spacing-unit;
	 margin-top: $base-spacing-unit--lg;
  }
}

// [1] calculate height based on padding for mod_article, including fallback
// [2] IE11: IE needs a height for flexbox. Will be overwritten by min-height
.news-full--hero {
  @include wrapper-max(none);
  @include padding-default;
    
  overflow: visible;
  
  .layout_full {
    overflow: visible;
  }
  
  .hero {
	  background-color: $news-hero-background-color;
    color: #fff;
    position: relative;
    z-index: 0;
    overflow: hidden;
    height: 0; // [2]
    min-height: 80vh; // [1]
    min-height: calc(80vh - #{2 * $base-spacing-unit--lg}); // [1]
    
    margin-left: -1 * $grid__gutter--half;
    margin-right: -1 * $grid__gutter--half;
    padding-left: $grid__gutter--half;
    padding-right: $grid__gutter--half;
    margin-bottom: $base-spacing-unit--lg;
    display: flex;
    
    @if($ie9-support == true) {
      .ie9 & {
  	    height: calc(80vh - #{2 * $base-spacing-unit--lg}); // [2][3]
  	  }
    }
  }
  
  .hero__image .image_container img {
    opacity: 0.3;
  }
  
  .hero__image .image_container.compat-object-fit img {
	  opacity: 0;
  }
  
  .hero__image .image_container.compat-object-fit {
	  opacity: 0.3;
  }
  
  .text_container {
    @include make-width(9);
  }
  
  .hero__text {
    @include make-width(9);
    
    display: block;    
    margin-top: $header-height;
    margin-bottom: $header-height;
    position: relative;
    z-index: 1000;
    
    @if($ie9-support == true) {
	    .ie9 & {
		    @include vertical-align();
		    
		    margin-top: 0;
		    margin-bottom: 0;
	    }
    }
    
    @include media-query(screen-md) {
      @include make-width(10);
    }
  }
  
  .back {
    @include make-width(9);
    
    font-family: $base-font-family-sans-serif;
    
    a {
      @include media-query(screen-sm) {
        @include make-width(9);
      } 
      
      display: block;  
      text-decoration: none; 
      
      &:before {
       font-family: 'FontAwesome';
		content: "\f137";
		font-size: 1.2rem;
		margin-left: 0.1rem;
		color: $color-brand;
        margin-right: $base-spacing-unit--xs;
      }
      
      &:hover {
	      color: $color-brand;
      }    
      }
  }
  
  .ce_comments {
    @include media-query(screen-sm) {
      @include make-width(9);
    } 
  }
}

.news-menu {
  
  > { 
    #{headings()} {
      @extend %h5;
      
      &:after {
        margin-top: $base-spacing-unit--xs;
      }
    }
  }

  a,
  .active {
    text-decoration: none;
    color: inherit;
    font-family: $headings__font-family;
  }
  
  .level_2 a,
  .level_2 span.active {
    vertical-align: middle;
    text-decoration: none;
    
    &:before {
      content: "»";
      margin-right: $base-spacing-unit--xs / 2;
    }
  }
    
  .year {
    > a,
    > .active {
      font-weight: $headings__font-weight;
    }
  }
}


#blog {
	padding-top: 3rem;
	
	@include media-query(screen-offcanvas) {
		padding-top: 8rem;
	}
	
	.news-grid {
		margin-right: 0;
		padding-top: 0;
		@include media-query(screen-offcanvas) {
		  margin-right: 3rem;
		  padding-top: 6rem;
	    } 		
	}
	
	.layout_latest__wrapper {
		 padding: 0 $base-spacing-unit--sm $base-spacing-unit--sm $base-spacing-unit--sm;
		 
		 h2 {
			 font-size: 1.3rem;
		 }
		 
	 }
	 
	 .border-top {
		 padding-bottom: 2rem;
		 width: 50%;
	 }
	 
	 .border-bottom {
		 padding-top: 1rem;
		  padding-bottom: 2rem;
	 }
	
}
